<template>
<div class="card mt-3">
    <ul class="list-group list-group-flush">

        <router-link router-link :to="{ name: 'ProfileInfo'}" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center flex-wrap">
            <h6 class="mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar2-check-fill" viewBox="0 0 16 16">
                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
            </svg> Osnovne informacije</h6>
        </router-link>

        <router-link v-if="role === 'WORKER'" router-link :to="{ name: 'EmployeeJobs'}" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center flex-wrap">
            <h6 class="mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar2-check-fill" viewBox="0 0 16 16">
                <path d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15h9.286zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zM.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8H.8z"/>
            </svg> Istorija dodeljenih poslova</h6>
        </router-link>

        <router-link router-link :to="{ name: 'EmployeeAbsences'}" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center flex-wrap">
            <h6 class="mb-0">
                <i class="fas fa-bed fa-lg" /> 
                Odsustva
            </h6>
        </router-link>
        <router-link router-link :to="{ name: 'EmployeeCarnet'}" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center flex-wrap">
            <h6 class="mb-0">
                <font-awesome-icon icon="calendar-alt"></font-awesome-icon>
                Karnet
            </h6>
        </router-link>
        <router-link router-link :to="{ name: 'EmployeeTools'}" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center flex-wrap">
            <h6 class="mb-0">
                <font-awesome-icon icon="tools"></font-awesome-icon>
                Alat
            </h6>
        </router-link>

    </ul>
</div>
</template>

<script>
export default {

    name: "ProfileLinks",

    props: ['role']

}
</script>

<style scoped>
.flex-wrap:hover { color: black; }
.flex-wrap:focus { 
  color: black; 
  -moz-box-shadow: 0 0 1px grey;
  -webkit-box-shadow: 0 0 1px grey;
  box-shadow: 0 0 1px grey;
  }
</style>